<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/chip_mounter/hm520hs">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="https://www.hanwha-pm.com/upload/product/20210517/183621DAki.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">HM520 HS</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Cutting-edge Modular Mounter
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/chip_mounter/hm520mf">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="https://www.hanwha-pm.com/upload/product/20210526/105059mppb.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">HM520 MF</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Cutting-edge Modular Mounter
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/chip_mounter/hm510">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="https://www.hanwha-pm.com/upload/product/20210517/143638cBGl.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">HM510</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Odd-Shape Modular Mounter
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/chip_mounter/decanf2">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="https://www.hanwha-pm.com/upload/product/20210517/185258sjGB.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">DECAN F2</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Advanced Flexible Placer
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/chip_mounter/decanl2">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="https://www.hanwha-pm.com/upload/product/20210517/185621FEed.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">DECAN L2</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Advanced Multi- Functional Placer
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/chip_mounter/decans2">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%"
                class="card-img-top img-fluid"
                src="https://www.hanwha-pm.com/upload/product/20210517/184512qAvl.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">DECAN S2</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Advanced Chip Shooter
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->

        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/chip_mounter/decans1">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="https://www.hanwha-pm.com/upload/product/20210517/194402ADxj.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">DECAN S1</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Fast & Flexible Mounter
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->

        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/chip_mounter/decands">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="https://www.hanwha-pm.com/upload/product/20210517/194924sugp.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">DECAN DS</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  High Effective LED Mounter
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/chip_mounter/sm471plus">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="https://www.hanwha-pm.com/upload/product/20210517/195328tjAC.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">SM471PLUS</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Fast Chip Shooter
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/chip_mounter/sm481plus">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%"
                class="card-img-top img-fluid"
                src="https://www.hanwha-pm.com/upload/product/20210517/195924jkbA.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">SM481PLUS</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Flexible Placer
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->

        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/chip_mounter/sm482plus">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="https://www.hanwha-pm.com/upload/product/20210517/200416AFih.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">SM482PLUS</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Multi-Functional Placer
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->

        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/chip_mounter/12scm1-d">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="https://www.hanwha-pm.com/upload/product/20210517/202453eJea.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">SCM1-D</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Shield Can
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>